import * as React from "react";
import {
  ArrayField,
  ArrayInput,
  BooleanField,
  BooleanInput,
  ChipField,
  CloneButton,
  Create,
  CreateButton,
  Datagrid,
  Edit,
  EditButton,
  List,
  NumberInput,
  ReferenceManyField,
  required,
  SaveButton,
  Show,
  SimpleForm,
  SimpleFormIterator,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar
} from "react-admin";
import {Chip, Typography} from '@material-ui/core'

const TextArrayField = ({record, source}) => {
    const array = record[source]
    if (typeof array === 'undefined' || array === null || array.length === 0) {
        return <div/>
    } else {
        return (
            <>
                {array.map(item => <Chip label={item} key={item}/>)}
            </>
        )
    }
}
TextArrayField.defaultProps = {addLabel: true}

const RealmEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
);

const validateRequired = [required()];

const PropertyMapField = ({record, source}) => {
    const property_map = record[source];
    console.log(property_map);
    const keys = Object.keys(property_map).map(item => {
        return {key: item, value: source[item]}
    });
    return (
        <ArrayField source={keys} label="">
            <Datagrid>
                <TextField label="" source="key"/>
                <TextField label="" source="value"/>
            </Datagrid>
        </ArrayField>
    )
}
PropertyMapField.defaultProps = {addLabel: true}

export const RealmsList = (props) => (
    <List pagination={false} {...props} >
        <Datagrid rowClick="show" isRowSelectable={record => false}>
            <TextField source="id"/>
            <TextField source="version"/>
            <EditButton/>
        </Datagrid>
    </List>
);

const RealmEditFormElements = (props) => (
    <>
        <TextInput fullWidth="true" source="custom_domain" label="Custom domain"/>
        <TextInput fullWidth="true" source="legacy_issuer_host" label="Legacy Issuer Host" />
        <Typography variant="h6" gutterBottom>Domains and Issuer Hosts</Typography>
        <ArrayInput source="domains" label="Domains and Issuer Hosts">
          <SimpleFormIterator>
            <TextInput fullWidth="true" source="domain" label="Domain"/>
            <TextInput fullWidth="true" source="issuer" label="Issuer Host"/>
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput fullWidth="true" source="jwks_expiration_seconds" label="JWKS expiration (s)"
                   validate={validateRequired} defaultValue={15780000}/>

        <Typography variant="h6" gutterBottom>Session Settings</Typography>
        <NumberInput fullWidth="true" source="session_settings.length_in_seconds" label="Session length (seconds)"
                     validate={validateRequired} defaultValue={31556926}/>
        <NumberInput fullWidth="true" source="session_settings.max_idle_length_in_seconds" label="Max idle length (seconds)"
                     validate={validateRequired} defaultValue={31556926}/>

        <ArrayInput source="scopes" label="Scopes">
            <SimpleFormIterator>
                <TextInput fullWidth="true" label=""/>
            </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="lip_hosts" label="LIP hosts">
            <SimpleFormIterator>
                <TextInput fullWidth="true" source="name" label="Name"/>
                <TextInput fullWidth="true" source="base_url" label="Base URL"/>
                <TextInput fullWidth="true" source="proxy_host" label="Proxy host"/>
                <NumberInput fullWidth="true" source="proxy_port" label="Proxy port"/>
                <BooleanInput fullWidth="true" source="default" label="Is default?"/>
            </SimpleFormIterator>
        </ArrayInput>

        <Typography variant="h6" gutterBottom>Token Settings</Typography>
        <TextInput fullWidth="true" source="token_settings.refresh_token_expiration_seconds"
                   label="Refresh Token expiration (seconds)" initialValue="31536000"/>

        <Typography variant="h6" gutterBottom>Facebook Settings</Typography>
        <BooleanInput fullWidth="true" source="auth_provider_settings.facebook.web_enabled" label="Web enabled"/>
        <BooleanInput fullWidth="true" source="auth_provider_settings.facebook.api_enabled" label="API enabled"/>
        <TextInput fullWidth="true" source="auth_provider_settings.facebook.api_base_url" label="API base URL"/>
        <TextInput fullWidth="true" source="auth_provider_settings.facebook.web_app_id" label="Web FB app id"/>
        <TextInput fullWidth="true" source="auth_provider_settings.facebook.web_app_secret" label="Web FB app secret"/>

        <Typography variant="h6" gutterBottom>Google Settings</Typography>
        <TextInput fullWidth="true" source="auth_provider_settings.google.api_issuer_url" label="API Google issuer url"/>
        <BooleanInput fullWidth="true" source="auth_provider_settings.google.web_enabled" label="Web enabled"/>
        <TextInput fullWidth="true" source="auth_provider_settings.google.web_app_id" label="Web Google app id"/>
        <TextInput fullWidth="true" source="auth_provider_settings.google.web_app_secret" label="Web Google app secret"/>

        <Typography variant="h6" gutterBottom>Apple Settings</Typography>
        <BooleanInput fullWidth="true" source="auth_provider_settings.apple.web_enabled" label="Web enabled?"/>
        <TextInput fullWidth="true" source="auth_provider_settings.apple.web_base_url" label="Web base URL"/>
        <TextInput fullWidth="true" source="auth_provider_settings.apple.web_client_id" label="Web client ID"/>
        <BooleanInput fullWidth="true" source="auth_provider_settings.apple.api_enabled" label="API enabled?"/>
        <TextInput fullWidth="true" source="auth_provider_settings.apple.api_base_url" label="API base URL"/>
        <NumberInput fullWidth="true" source="auth_provider_settings.apple.refresh_interval_hours"
                     label="Refresh interval (hours)" initialValue="24"/>
        <BooleanInput fullWidth="true" source="auth_provider_settings.apple.silence_refresh_errors"
                      label="Silence refresh errors?"/>
        <TextInput fullWidth="true" source="auth_provider_settings.apple.client_private_key" label="Private key"/>
        <TextInput fullWidth="true" source="auth_provider_settings.apple.client_key_id" label="Private key ID"/>
        <TextInput fullWidth="true" source="auth_provider_settings.apple.client_issuer" label="Issuer (Team ID)"/>

        <Typography variant="h6" gutterBottom>Odin Settings</Typography>
        <BooleanInput fullWidth="true" source="event_publishing_settings.odin.enabled" label="Enabled?"/>
        <TextInput fullWidth="true" source="event_publishing_settings.odin.ignore_header" label="Ignore header" initialValue="x-dnt"/>
        <BooleanInput fullWidth="true" source="event_publishing_settings.odin.http_enabled" label="HTTP enabled?"/>
        <TextInput fullWidth="true" source="event_publishing_settings.odin.http_base_url" label="HTTP base URL"/>
        <BooleanInput fullWidth="true" source="event_publishing_settings.odin.pubsub_enabled" label="PubSub enabled?"/>
        <TextInput fullWidth="true" source="event_publishing_settings.odin.pubsub_project" label="PubSub project"/>
        <TextInput fullWidth="true" source="event_publishing_settings.odin.pubsub_topic" label="PubSub topic"/>

        <Typography variant="h6" gutterBottom>Account Flow Settings</Typography>
        <NumberInput fullWidth="true" source="account_flow_settings.password_reset_jwt_expiration_seconds"
                     label="Password reset JWT expiration (seconds)" initialValue={900}/>
        <NumberInput fullWidth="true" source="account_flow_settings.account_confirmation_jwt_expiration_seconds"
                     label="Account confirmation JWT expiration (seconds)" initialValue={86400}/>
        <TextInput fullWidth="true" source="account_flow_settings.account_confirmation_base_url"
                     label="Account confirmation base url (domain for /confirm-email link)"/>
        <TextInput fullWidth="true" source="account_flow_settings.default_redirect_uri" label="Default redirect URI"/>
        <BooleanInput fullWidth="true" source="account_flow_settings.resend_confirmation_email_enabled" label="Resend confirmation email feature enabled?"/>
        <NumberInput fullWidth="true" source="account_flow_settings.resend_confirmation_email_limit" label="The number of allowed attempts to resend a confirmation email."/>
        <BooleanInput fullWidth="true" source="account_flow_settings.password_reset_nonce_enabled" label="Password reset cnonce enabled?"/>
        <BooleanInput fullWidth="true" source="account_flow_settings.breached_password_check_enabled" label="Breached password check is enabled in the accounts services?"/>
        <BooleanInput fullWidth="true" source="account_flow_settings.breached_password_check_block_user_creation" label="Block requests if the password is breached during user registration?"/>
        <TextInput fullWidth="true" source="account_flow_settings.authentication_server_url" label="The URL of the authentication server."/>
        <TextInput fullWidth="true" source="account_flow_settings.authentication_server_client_id" label="The client ID to get token to connect to the authentication server."/>
        <TextInput fullWidth="true" source="account_flow_settings.authentication_server_client_secret" label="The client secret to get token to connect to the authentication server."/>

      <ArrayInput source="rendering_hosts" label="Rendering hosts">
        <SimpleFormIterator>
          <TextInput fullWidth="true" source="name" label="Name"/>
          <TextInput fullWidth="true" source="base_url" label="Rendering service base UR"/>
          <TextInput fullWidth="true" source="username" label="Rendering service username"/>
          <TextInput fullWidth="true" source="password" label="Rendering service password"/>
          <TextInput fullWidth="true" source="iap_client_id" label="Rendering service IAP client ID"/>
          <BooleanInput fullWidth="true" source="default" label="Is default?"/>
        </SimpleFormIterator>
      </ArrayInput>

        <Typography variant="h6" gutterBottom>Email Settings</Typography>
        <BooleanInput fullWidth="true" source="email_settings.async_enabled" label="Async enabled?"/>
        <BooleanInput fullWidth="true" source="email_settings.knocker_enabled" label="Knocker enabled?"/>
        <TextInput fullWidth="true" source="email_settings.knocker_service_base_path" label="Knocker Service base url"/>
        <TextInput fullWidth="true" source="email_settings.knocker_service_key" label="Service key to use for Knocker service(see https://docs.mpi-internal.com/spt-marketplace/knocker/getting_started/#step-1-request-a-service-key)"/>
        <TextInput fullWidth="true" source="email_settings.application" label="Application"/>

        <Typography variant="h6" gutterBottom>Sms Settings</Typography>
        <BooleanInput fullWidth="true" source="sms_settings.enabled" label="Enabled?"/>
        <TextInput fullWidth="true" source="sms_settings.knocker_service_base_path" label="Knocker Service base url"/>
        <TextInput fullWidth="true" source="sms_settings.knocker_service_key" label="Service key to use for Knocker service(see https://docs.mpi-internal.com/spt-marketplace/knocker/getting_started/#step-1-request-a-service-key)"/>

      <Typography variant="h6" gutterBottom>OTP Settings</Typography>
      <TextInput fullWidth="true" source="otp_settings.max_attempts"
                 label="Maximum number of attempts an OTP can be verified"/>
      <TextInput fullWidth="true" source="otp_settings.max_issued" label="Maximum number of issued OTPs in a certain time period"/>
      <TextInput fullWidth="true" source="otp_settings.expiration_time"
                 label="OTP expiration time - ISO-8601 duration format such as PnDTnHnMn.nS for example PT15M (15 minutes), PT6H (6 hours)"/>
      <TextInput fullWidth="true" source="otp_settings.block_period"
                 label="OTP block period after maximum issued OTP count exceeded - ISO-8601 duration format such as PnDTnHnMn.nS for example PT1M (1 minute), PT12H (12 hours)"/>

      <Typography variant="h6" gutterBottom>Themes</Typography>
      <ArrayInput source="themes" label="Themes">
        <SimpleFormIterator>
          <TextInput fullWidth="true" source="name" label="Name"/>
          <BooleanInput fullWidth="true" source="default" label="Is default?"/>
          <ArrayInput source="property_map_assoc" label="">
            <SimpleFormIterator>
              <TextInput label="Key" source="key"/>
              <TextInput label="Value" source="value"/>
            </SimpleFormIterator>
          </ArrayInput>
            </SimpleFormIterator>
        </ArrayInput>

        <Typography variant="h6" gutterBottom>Locales</Typography>
        <ArrayInput source="locales" label="Locales">
            <SimpleFormIterator>
                <TextInput fullWidth="true" source="name" label="Name"/>
                <BooleanInput fullWidth="true" source="default" label="Is default?"/>
                <ArrayInput source="property_map_assoc" label="">
                    <SimpleFormIterator>
                        <TextInput label="Key" source="key"/>
                        <TextInput label="Value" source="value"/>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleFormIterator>
        </ArrayInput>

      <Typography variant="h6" gutterBottom>Secure Response Headers</Typography>
      <TextInput fullWidth="true" source="secure_response_headers.content_security_policy" label="Security Content Policy" initialValue={"frame-ancestors 'none'"}/>
      <TextInput fullWidth="true" source="secure_response_headers.content_security_policy_report_only" label="Security Content Policy Report Only"/>
    </>
)

export const RealmEdit = (props) => (
    <Edit undoable={false} {...props}>
        <SimpleForm toolbar={<RealmEditToolbar/>}>
            <TextField fullWidth="true" source="id" label="ID"/>
            <TextField fullWidth="true" source="version" label="Version"/>
            <RealmEditFormElements/>
        </SimpleForm>
    </Edit>
);

export const RealmCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput fullWidth="true" source="id" label="ID" validate={validateRequired}/>
            <RealmEditFormElements/>
        </SimpleForm>
    </Create>
);

export const RealmShow = (props) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Details">
                <TextField fullWidth="true" source="id" label="ID"/>
                <TextField fullWidth="true" source="created_date" label="Created Date"/>
                <TextField fullWidth="true" source="created_by" label="Created By"/>
                <TextField fullWidth="true" source="last_modified_date" label="Last Modified Date"/>
                <TextField fullWidth="true" source="last_modified_by" label="Last Modified By"/>
                <TextField fullWidth="true" source="version" label="Version"/>
                <TextField fullWidth="true" source="custom_domain" label="Custom domain"/>
                <TextField fullWidth="true" source="legacy_issuer_host" label="Legacy Issuer Host" />
                <Typography variant="h6" gutterBottom>Domains and Issuer Hosts</Typography>
                <ArrayField source="domains" label="Domains and Issuer Hosts">
                  <Datagrid>
                    <TextField fullWidth="true" source="domain" label="Domain"/>
                    <TextField fullWidth="true" source="issuer" label="Issuer Host"/>
                  </Datagrid>
                </ArrayField>
                <TextField fullWidth="true" source="jwks_expiration_seconds" label="JWKS expiration (s)"/>

                <Typography variant="h6" gutterBottom>Session Settings</Typography>
                <TextField fullWidth="true" source="session_settings.length_in_seconds" label="Session length (seconds)"/>
                <TextField fullWidth="true" source="session_settings.max_idle_length_in_seconds" label="Max idle length (seconds)"/>

                <Typography variant="h6" gutterBottom>Scopes</Typography>
                <TextArrayField source="scopes" label="">
                    <SingleFieldList>
                        <ChipField source="id"/>
                    </SingleFieldList>
                </TextArrayField>

                <Typography variant="h6" gutterBottom>LIP hosts</Typography>
                <ArrayField source="lip_hosts" label="">
                    <Datagrid>
                        <TextField fullWidth="true" source="name" label="Name"/>
                        <TextField fullWidth="true" source="base_url" label="Base URL"/>
                        <TextField fullWidth="true" source="proxy_host" label="Proxy host"/>
                        <TextField fullWidth="true" source="proxy_port" label="Proxy port"/>
                        <BooleanField fullWidth="true" source="default" label="Is default?"/>
                    </Datagrid>
                </ArrayField>

                <Typography variant="h6" gutterBottom>Token Settings</Typography>
                <TextField fullWidth="true" source="token_settings.refresh_token_expiration_seconds"
                           label="Refresh Token expiration (seconds)"/>

                <Typography variant="h6" gutterBottom>Facebook Settings</Typography>
                <BooleanField fullWidth="true" source="auth_provider_settings.facebook.web_enabled"
                              label="Web enabled?"/>
                <BooleanField fullWidth="true" source="auth_provider_settings.facebook.api_enabled"
                              label="API enabled?"/>
                <TextField fullWidth="true" source="auth_provider_settings.facebook.api_base_url" label="API base URL"/>
                <TextField fullWidth="true" source="auth_provider_settings.facebook.web_app_id" label="Web FB app id"/>
                <TextField fullWidth="true" source="auth_provider_settings.facebook.web_app_secret"
                           label="Web FB app secret"/>

                <Typography variant="h6" gutterBottom>Google Settings</Typography>
                <TextField fullWidth="true" source="auth_provider_settings.google.api_issuer_url"
                           label="API Google issuer url"/>
                <BooleanField fullWidth="true" source="auth_provider_settings.google.web_enabled"
                              label="Web enabled?"/>
                <TextField fullWidth="true" source="auth_provider_settings.google.web_app_id"
                           label="Web Google app id"/>
                <TextField fullWidth="true" source="auth_provider_settings.google.web_app_secret"
                           label="Web Google app secret"/>

                <Typography variant="h6" gutterBottom>Apple Settings</Typography>
                <BooleanField fullWidth="true" source="auth_provider_settings.apple.web_enabled" label="Web enabled?"/>
                <TextField fullWidth="true" source="auth_provider_settings.apple.web_base_url" label="Web base URL"/>
                <TextField fullWidth="true" source="auth_provider_settings.apple.web_client_id" label="Web client ID"/>
                <BooleanField fullWidth="true" source="auth_provider_settings.apple.api_enabled" label="API enabled?"/>
                <TextField fullWidth="true" source="auth_provider_settings.apple.api_base_url" label="API base URL"/>
                <TextField fullWidth="true" source="auth_provider_settings.apple.refresh_interval_hours"
                           label="Refresh interval (hours)"/>
                <BooleanField fullWidth="true" source="auth_provider_settings.apple.silence_refresh_errors"
                              label="Silence refresh errors?"/>
                <TextField fullWidth="true" source="auth_provider_settings.apple.client_private_key"
                           label="Private key"/>
                <TextField fullWidth="true" source="auth_provider_settings.apple.client_key_id" label="Private key ID"/>
                <TextField fullWidth="true" source="auth_provider_settings.apple.client_issuer"
                           label="Issuer (Team ID)"/>

                <Typography variant="h6" gutterBottom>Odin Settings</Typography>
                <BooleanField fullWidth="true" source="event_publishing_settings.odin.enabled" label="Enabled?"/>
                <TextField fullWidth="true" source="event_publishing_settings.odin.ignore_header"
                           label="Ignore header"/>
                <BooleanField fullWidth="true" source="event_publishing_settings.odin.http_enabled"
                              label="HTTP enabled?"/>
                <TextField fullWidth="true" source="event_publishing_settings.odin.http_base_url"
                           label="HTTP base URL"/>
                <BooleanField fullWidth="true" source="event_publishing_settings.odin.pubsub_enabled"
                              label="PubSub enabled?"/>
                <TextField fullWidth="true" source="event_publishing_settings.odin.pubsub_project"
                           label="PubSub project"/>
                <TextField fullWidth="true" source="event_publishing_settings.odin.pubsub_topic" label="PubSub topic"/>

                <Typography variant="h6" gutterBottom>Account Flow Settings</Typography>
                <TextField fullWidth="true" source="account_flow_settings.password_reset_jwt_expiration_seconds"
                           label="Password reset JWT expiration (seconds)"/>
                <TextField fullWidth="true" source="account_flow_settings.account_confirmation_jwt_expiration_seconds"
                           label="Account confirmation JWT expiration (seconds)"/>
                <TextField fullWidth="true" source="account_flow_settings.account_confirmation_base_url"
                           label="Account confirmation base url (domain for /confirm-email link)"/>
                <TextField fullWidth="true" source="account_flow_settings.default_redirect_uri"
                           label="Default redirect URI"/>
              <BooleanField fullWidth="true" source="account_flow_settings.resend_confirmation_email_enabled" label="Resend confirmation email feature enabled?"/>
              <TextField fullWidth="true" source="account_flow_settings.resend_confirmation_email_limit" label="The number of allowed attempts to resend a confirmation email."/>
              <BooleanField fullWidth="true" source="account_flow_settings.password_reset_nonce_enabled" label="Password reset cnonce enabled?"/>
              <BooleanField fullWidth="true" source="account_flow_settings.breached_password_check_enabled" label="Breached password check is enabled in the accounts services?"/>
              <BooleanField fullWidth="true" source="account_flow_settings.breached_password_check_block_user_creation" label="Block requests if the password is breached during user registration?"/>
              <TextField fullWidth="true" source="account_flow_settings.authentication_server_url" label="The URL of the authentication server."/>
              <TextField fullWidth="true" source="account_flow_settings.authentication_server_client_id" label="The client ID to get token to connect to the authentication server."/>
              <TextField fullWidth="true" source="account_flow_settings.authentication_server_client_secret" label="The client secret to get token to connect to the authentication server."/>

                <Typography variant="h6" gutterBottom>Rendering hosts</Typography>
                <ArrayField source="rendering_hosts" label="">
                  <Datagrid>
                    <TextField fullWidth="true" source="name" label="Name"/>
                    <TextField fullWidth="true" source="base_url" label="Rendering service base URL"/>
                    <TextField fullWidth="true" source="username" label="Rendering service username"/>
                    <TextField fullWidth="true" source="password" label="Rendering service password"/>
                    <TextField fullWidth="true" source="iap_client_id" label="Rendering service IAP client ID"/>
                    <BooleanField fullWidth="true" source="default" label="Is default?"/>
                  </Datagrid>
                </ArrayField>

                <Typography variant="h6" gutterBottom>Email Settings</Typography>
                <BooleanField fullWidth="true" source="email_settings.async_enabled" label="Async enabled?"/>
              <BooleanField fullWidth="true" source="email_settings.knocker_enabled" label="Knocker enabled?"/>
              <TextField fullWidth="true" source="email_settings.knocker_service_base_path"
                         label="Knocker Service base url"/>
              <TextField fullWidth="true" source="email_settings.knocker_service_key"
                         label="Service key to use for Knocker service(see https://docs.mpi-internal.com/spt-marketplace/knocker/getting_started/#step-1-request-a-service-key)"/>
              <TextField fullWidth="true" source="email_settings.application" label="Application"/>

              <Typography variant="h6" gutterBottom>Sms Settings</Typography>
              <BooleanField fullWidth="true" source="sms_settings.enabled" label="Enabled?"/>
              <TextField fullWidth="true" source="sms_settings.knocker_service_base_path"
                         label="Knocker Service base url"/>
              <TextField fullWidth="true" source="sms_settings.knocker_service_key"
                         label="Service key to use for Knocker service(see https://docs.mpi-internal.com/spt-marketplace/knocker/getting_started/#step-1-request-a-service-key)"/>

              <Typography variant="h6" gutterBottom>OTP Settings</Typography>
              <TextField fullWidth="true" source="otp_settings.max_attempts"
                         label="Maximum number of attempts an OTP can be verified - default 5"/>
              <TextField fullWidth="true" source="otp_settings.max_issued" label="Maximum number of issued OTPs in a certain time period - default 5"/>
              <TextField fullWidth="true" source="otp_settings.expiration_time"
                         label="OTP expiration time - ISO-8601 duration format such as PnDTnHnMn.nS for example PT15M (15 minutes), PT6H (6 hours) - default PT15M"/>
              <TextField fullWidth="true" source="otp_settings.block_period"
                         label="OTP block period after maximum issued OTP count exceeded - ISO-8601 duration format such as PnDTnHnMn.nS for example PT1M (1 minute), PT12H (12 hours) - default PT6H"/>

              <Typography variant="h6" gutterBottom>Themes</Typography>
              <ArrayField source="themes" label="Themes">
                <Datagrid>
                  <TextField fullWidth="true" source="name" label="Name"/>
                  <BooleanField fullWidth="true" source="default" label="Is default?"/>
                  <ArrayField source="property_map_assoc" label="">
                    <Datagrid>
                      <TextField label="Key" source="key"/>
                      <TextField label="Value" source="value"/>
                    </Datagrid>
                  </ArrayField>
                    </Datagrid>
                </ArrayField>

                <Typography variant="h6" gutterBottom>Locales</Typography>
                <ArrayField source="locales" label="Locales">
                    <Datagrid>
                        <TextField fullWidth="true" source="name" label="Name"/>
                        <BooleanField fullWidth="true" source="default" label="Is default?"/>
                        <ArrayField source="property_map_assoc" label="">
                            <Datagrid>
                                <TextField label="Key" source="key"/>
                                <TextField label="Value" source="value"/>
                            </Datagrid>
                        </ArrayField>
                    </Datagrid>
                </ArrayField>

              <Typography variant="h6" gutterBottom>Additional Response Headers</Typography>
              <TextField fullWidth="true" source="secure_response_headers.content_security_policy" label="Security Content Policy"/>
              <TextField fullWidth="true" source="secure_response_headers.content_security_policy_report_only" label="Security Content Policy Report Only"/>

            </Tab>
            <Tab label="Clients">
                <TopToolbar>
                    <CreateButton basePath="/clients"/>
                </TopToolbar>
                <ReferenceManyField label="" reference="clients" target="realm_id">
                    <Datagrid rowClick="edit" isRowSelectable={record => true}>
                      <TextField label="Client ID" source="client_id"/>
                      <TextField label="Client Name" source="client_name"/>
                      <TextField label="Version" source="version"/>
                      <CloneButton />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
